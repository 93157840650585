import dataApi from '@/api/apply.js'
import {
	store,
	mutations
} from "@/store/index.js";
import {
	countDown
} from '@/util/tools.js'
export const answer = {
	methods: {
		//检查 未做的题
		checkSubStatus() { 
			let unDown = []
			this.mockJSon.forEach((item,index) => {
				if (item.answer == '') {
					unDown.push(index+1 )
				}
			})
			// 检查完并且排序
			return unDown.sort(((a,b)=>a-b))
		},
		//拉取题目列表
		async getPaper() { 
			let data = null
			data = await dataApi.getPaper({type:store.gameType}).catch((res)=>{
				this.$router.replace('/')
			})
			if(data.data.no){
				mutations.setGameno(data.data.no)
			}
			/* 格式化题目 */
			this.mockJSon = data.data.question.map(item => {
				if (item.style == '1' || item.style == '5') { //单选		
					let op = item.option.split(',').map(item => item.split(':'))
					return Object.assign(item, {
						formatOption: op
					})
				} else if (item.style == '2') { //多选
					let op = item.option.split(',').map(item => item.split(':'))
					let mapOp = op.map(item => {
						return Object.assign(item, {
							bOn: false
						})
					})
					return Object.assign(item, {
						formatOption: mapOp
					})
				} else if (item.style == '3') { // 填空
					return item
				} else if (item.style == '4') { // 判断
					let op = {
						"0": '正确',
						'1': '错误'
					}
					return Object.assign(item, {
						formatOption: op
					})
				}
			})
			this.answerTime = data.data.lastTime
			this.unDown = this.checkSubStatus()
			// this.currentSub = this.mockJSon[this.oSubIndex]
		},
		async checkStudentState() {
			const data = await dataApi.checkState()
			this.STATE = data.data.allowEval.code;
			this.getPaper()
		},
		windowActive() {
			if (document.visibilityState === 'hidden') {
				// this.answerList[this.currentSub['no']] = this.currentSub
				this.handPaper(1)
			}
			// 用户打开或回到页面
			if (document.visibilityState === 'visible') {
				this.handPaper(1)
			}
		}
	},
	mounted() {
		if (localStorage.getItem('oSubIndex')) { // 定位二次进入的题目位置
			this.oSubIndex = JSON.parse(localStorage.getItem('oSubIndex'))
			localStorage.removeItem('oSubIndex')
		}
		this.checkStudentState()
		// dataApi.clearPaper()
		this.countTimer = setInterval(() => { //考试计时器
			if (this.answerTime == 0) { //  考试结束
				clearInterval(this.countTimer)
				clearInterval(this.lastTimer)
				this.enterAgain = false
				this.commitFinish = false
				this.commitUnfinish = false
				this.$notify('考试时间到')
				this.handPaper(2,2)
				return
			}
			this.answerTime--
			this.timeRemaning = countDown(this.answerTime)
			document.title = '倒计时' + " " + this.timeRemaning
		}, 1000)

		// 轮询请求访问功能
		let randomNum = Math.floor((Math.random() * (30 - 20)) + 20);
		this.lastTimer = setInterval(() => {
			this.handPaper(1)
		}, 1000 * randomNum)

		document.addEventListener('visibilitychange',this.windowActive);
	},
	beforeRouteLeave(to, from, next) {
		document.removeEventListener('visibilitychange', this.windowActive)
		next()
	},
}
