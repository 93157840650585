import { render, staticRenderFns } from "./ExamRead.vue?vue&type=template&id=ee9775aa&scoped=true&"
import script from "./ExamRead.vue?vue&type=script&lang=js&"
export * from "./ExamRead.vue?vue&type=script&lang=js&"
import style0 from "./ExamRead.vue?vue&type=style&index=0&id=ee9775aa&scoped=scoped&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ee9775aa",
  null
  
)

export default component.exports