<template>
  <div class="box">
    <div class="describ" flex="cross:center" v-if="editStatus">
      <img src="@/assets/bindStudent/icon_zhuyi@2x.png" alt="" srcset="" />
      您只能绑定一个学生，请认真填写信息
    </div>
    <van-form
      @failed="onFailed"
      @submit="onSubmit"
      :show-error-message="false"
      :style="{ opacity: editStatus ? '1' : '0.5' }"
      validate-trigger="onSubmit"
    >
      <van-field
        :disabled="!editStatus"
        input-align="right"
        v-model="form.name"
        name="name"
        label="姓名"
        placeholder="请输入姓名"
        :rules="[{ required: true, message: '请输入姓名' }]"
        :maxlength="16"
      />
      <van-field name="sex" label="性别">
        <template #input>
          <van-radio-group
            v-model="form.sex"
            direction="horizontal"
            :disabled="!editStatus"
          >
            <van-radio name="1" class="sex-boy">男</van-radio>
            <van-radio name="2" class="sex-girl">女</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field name="stage" label="当前学段">
        <template #input>
          <van-radio-group
            v-model="form.stage"
            direction="horizontal"
            @change="changeStage"
            :disabled="!editStatus"
          >
            <van-radio name="1" class="sex-boy">小学</van-radio>
            <van-radio name="2" class="sex-girl">初中</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        input-align="right"
        v-model="form.cityName"
        name="cityName"
        label="学校所在城市"
        placeholder="请选择城市"
        :right-icon="editStatus ? 'arrow' : ''"
        readonly=""
        clickable
        @click="showCity"
        :rules="[{ required: true, message: '请选择城市' }]"
      />
      <van-popup v-model="showCityPicker" round position="bottom">
        <van-picker
          show-toolbar
          :columns="columns"
          @cancel="showCityPicker = false"
          @confirm="onConfirmCity"
        />
      </van-popup>
      <van-field
        :disabled="!editStatus"
        input-align="right"
        v-model="form.schoolName"
        name="schoolName"
        label="学校"
        placeholder="请填写学校"
        :rules="[{ required: true, message: '请填写学校' }]"
        :maxlength="32"
      />

      <van-field
        :disabled="!editStatus"
        input-align="right"
        v-model="form.grade"
        name="grade"
        label="年级"
        placeholder="请选择年级"
        :right-icon="editStatus ? 'arrow' : ''"
        readonly=""
        clickable
        @click="showGrade"
        :rules="[{ required: true, message: '请选择年级' }]"
      />
      <van-popup v-model="showGradePicker" round position="bottom">
        <van-picker
          show-toolbar
          :columns="columnsGrade"
          @cancel="showGradePicker = false"
          @confirm="onConfirmGrade"
        />
      </van-popup>

      <van-field
        :disabled="!editStatus"
        input-align="right"
        v-model="form.className"
        name="className"
        label="班级"
        placeholder="请填写班级"
        :rules="[{ required: true, message: '请填写班级' }]"
        :maxlength="12"
      />
      <van-field
        :disabled="!editStatus"
        input-align="right"
        v-model="form.phone"
        name="phone"
        label="家长手机号"
        placeholder="请填写正确手机号"
        :rules="[{ validator, required: true, message: '请填写正确手机号' }]"
      />
      <!-- tool -->
      <van-button
        class="submit"
        v-if="editStatus"
        native-type="submit"
        color="#27C190"
        :disabled="loading"
        loading-text="提交中..."
        :loading="loading"
        >提交</van-button
      >
    </van-form>
<!--    <div class="error" v-if="!editStatus">-->
<!--      <div class="errorTip">-->
<!--        <p>信息填写有误？</p>-->
<!--        <p>长按下方图片添加客服进行修改</p>-->
<!--      </div>-->
<!--      <img src="@/assets/bindStudent/kefu.jpg" alt="">-->
<!--    </div>-->
  </div>
</template>

<script>
import dataApi from "@/api/apply.js";
export default {
  data() {
    return {
      form: {
        name: "",
        sex: "",
        stage: "",
        cityName: "",
        schoolName: "",
        grade: "",
        className: "",
        phone: "",
      },

      disabled: true,
      showCityPicker: false,
      showGradePicker: false,
      columns: [
        "杭州市",
        "宁波市",
        "温州市",
        "嘉兴市",
        "湖州市",
        "绍兴市",
        "金华市",
        "衢州市",
        "舟山市",
        "台州市",
        "丽水市",
        "阿克苏地区",
        "兵团第一师",
      ],
      columnsGrade: [
        "一年级",
        "二年级",
        "三年级",
        "四年级",
        "五年级",
        "六年级",
        "七年级",
        "八年级",
        "九年级",
      ],
      columnsGradeList: [
        "一年级",
        "二年级",
        "三年级",
        "四年级",
        "五年级",
        "六年级",
        "七年级",
        "八年级",
        "九年级",
      ],
      editStatus: false, // 是否进入修改状态
      gradeMap: new Map(),
      loading: false,
    };
  },
  created() {
    this.getBindInfo();
    // 年级的枚举
    this.columnsGradeList.forEach((item, index) => {
      this.gradeMap.set(item, index + 1);
    });
    const parentPhone = localStorage.getItem('parentPhone')
    if (parentPhone) {
      this.$set(this.form, 'phone', parentPhone)
    }
  },
  mounted() {
    this.loading = false
  },
  methods: {
    showCity() {
      if (!this.editStatus) {
        return;
      }
      this.showCityPicker = true;
    },
    showGrade() {
      if (!this.editStatus) {
        return;
      }
      this.showGradePicker = true;
    },
    onConfirmCity(value) {
      // 城市
      this.form.cityName = value;
      this.showCityPicker = false;
    },
    onConfirmGrade(value) {
      // this.gradeNameCopt
      this.form.grade = value;
      this.showGradePicker = false;
    },
    changeStage(val) {
      if(!this.editStatus) return
      this.form.grade = "";
      if (val == 1) {
        this.columnsGrade = this.columnsGradeList.slice(0, 6);
      } else {
        this.columnsGrade = this.columnsGradeList.slice(6, 9);
      }
    },
    checkName(name) {
      return new Promise((reslove, reject) => {
        if (name.indexOf(" ") == -1) {
          reslove();
        } else {
          this.$toast.fail("姓名不能存在空格");
          reject()
        }
      });
    },

    async onSubmit(values) {
       await this.checkName(values.name);
      if (!values.sex) {
        this.$toast.fail("性别未选择");
        return;
      }
      if (!values.stage) {
        this.$toast.fail("学段未选择");
        return;
      }
      this.loading = true
      values.grade = String(this.gradeMap.get(values.grade));
      dataApi.postBindInfo(values).then((res) => {
        this.$toast.success("绑定成功");
        dataApi.checkState().then((resMsg) => {
          const allowEval = resMsg.data.allowEval;
          if (allowEval.code == "OK" && allowEval.lastTime < 1) {
            this.$router.replace("/ExamRead");
          } else {
            this.$router.push("/");
          }
        })
      }).catch(err =>{
          this.loading = false
          this.$toast.fail(err.data.msg);
        });
    },
    onFailed(errorInfo) {
      console.log("failed", errorInfo);
    },
    validator(val) {
      return /^1\d{10}$/.test(val);
    },

    getBindInfo() {
      dataApi.getUserInfo().then((data) => {
        const res = data.data;
        if (res.name) {
          this.form.name = res.name;
          this.form.sex = String(res.sex);
          this.form.stage = String(res.stage);
          this.form.cityName = res.cityName;
          this.form.schoolName = res.schoolName;
          this.form.grade = res.grade;
          this.form.className = res.className;
          this.form.phone = res.phone;
          this.editStatus = false;
        } else {
          this.editStatus = true;
        }
      });
    },
  },
};
</script>
<style scoped="scoped" lang="less">
.van-form /deep/ .van-field__right-icon {
  color: #ccc;
  padding: 0 !important;
}
.van-form /deep/ .van-picker .van-picker__confirm {
  color: #27c190;
}
.van-form /deep/ .van-field__control {
  justify-content: flex-end;
}

.van-form /deep/ .van-radio--horizontal {
  margin-right: unset;
}

.van-form /deep/ .van-radio__icon {
  font-size: 0.28rem;
}
.van-form /deep/ .van-radio__icon--checked {
  .van-icon {
    background-color: #27c190;
    border-color: #27c190;
  }
}

.van-form /deep/ .gameTime .van-field__label {
  color: #666;
}

.van-form /deep/ .gameTime .van-field__control {
  color: #666;
}

.van-form /deep/ .van-field--disabled .van-field__label {
  color: #666;
}

.van-form /deep/ .van-field__control:disabled {
  color: #666;
  -webkit-text-fill-color: #666;
}
.van-form /deep/ .van-cell {
  height: 0.96rem;
  display: flex;
  font-size: 0.3rem;
  flex-direction: row;
  align-items: center;
  .van-field__label {
    color: #000000;
  }
  .van-field__error-message {
    text-align: right;
    font-size: 0.3rem;
  }
}
</style>
<style scoped="scoped" lang="less">
.box {
  width: 100%;
  overflow-x: hidden;
  height: 100vh;
  background: url("../../assets/bindStudent/img_bg_bangding@2x.png") no-repeat;
  background-position: bottom;
  background-size: 100% 36%;
  background-color: #f5f5f5;
}

.sex-boy {
  margin-right: 1.06rem !important;
}

.describ {
  font-size: 0.26rem;
  color: #2db2ea;
  text-align: left;
  height: 0.72rem;
  padding-left: 0.32rem;
  img {
    width: 0.26rem;
    height: 0.26rem;
    margin-right: 0.1rem;
  }
}

.error {
  text-align: center;
  color: rgba(0,0,0, .4);
  font-size: 13px;
  margin-top: 34px;
  font-family: PingFangSC-Regular;

  p {
    line-height: 9px;
  }

  img {
    margin-top: 8px;
    width: 100px;
    height: 100px;
  }
}

.ganmeTime-top {
  margin-top: 0.2rem;
}

.input {
  text-align: right;
}

.submit,
.edit {
  width: 6.86rem;
  height: 0.88rem;
  margin-top: 1.34rem;
  color: #fff;
  border-radius: 0.08rem;
  margin-left: 0.32rem;
  font-size: 0.32rem;
}
</style>
